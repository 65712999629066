<template>
  <div id="buttons-input-box" class="vac-box-footer">
    <div class="buttons-row">
      <button
        v-for="(button, idx) in buttons"
        :key="idx"
        class="button-element"
        @click="$emit('submitButton', button)"
      >
        {{ button.title }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Buttons',
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.buttons-row {
  text-align: center;
  background: inherit;
  width: 100%;
}
.button-element {
  margin: 3px;
  padding: 5px 10px 5px 10px;
  border: 2px solid;
  border-radius: 15px;
  font-size: 14px;
  background: inherit;
  cursor: pointer;
}
</style>
