var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('v-dialog',{attrs:{"outlined":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"outlined":"","light":_vm.theme != 'dark'}},[_c('v-card-text',{staticClass:"mt-2"},[_vm._v(" Please stand by "),_c('v-progress-linear',{staticClass:"mb-2 mt-2",attrs:{"indeterminate":"","light":_vm.theme != 'dark'}})],1)],1)],1)],1):_c('div',[(_vm.ended)?_c('div',{staticClass:"loading"},[_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.ended),callback:function ($$v) {_vm.ended=$$v},expression:"ended"}},[_c('v-card',{attrs:{"color":"black","dark":""}},[_c('v-card-text',[_vm._v(" Thank you for participating! "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1):_vm._e()]),(_vm.message)?_c('div',{staticClass:"session-state"},[_c('img',{attrs:{"id":"mp-logo","src":_vm.logoUrl,"alt":"Masspredict logo"}}),_c('div',{staticClass:"mb-6"},[_c('h2',{staticClass:"mp-info",style:(("color: " + (_vm.theme === 'dark' ? 'white' : 'black') + ";"))},[_vm._v(" "+_vm._s(_vm.message)+" ")])]),_c('v-btn',{attrs:{"color":"secondary","large":"","outlined":"","to":"/signup"}},[_vm._v(" Create your own study ")])],1):_vm._e(),(_vm.show)?_c('div',[_c('v-card',{attrs:{"height":_vm.calcHeight}},[_c('chat-window',{style:(_vm.cssProps),attrs:{"height":"100%","current-user-id":_vm.currentUserId,"rooms":_vm.rooms,"rooms-loaded":false,"messages":_vm.messages,"messages-loaded":true,"show-files":false,"show-emojis":false,"show-audio":false,"show-reaction-emojis":false,"show-new-messages-divider":false,"text-formatting":{ disabled: true },"message-actions":[],"room-info-enabled":false,"single-room":true,"text-messages":{ TYPE_MESSAGE: _vm.placeholder },"auto-scroll":{
                         send: {
                           new: true,
                           newAfterScrollUp: true,
                         },
                         receive: {
                           new: true,
                           newAfterScrollUp: true,
                         },
                       },"theme":"dark"},on:{"send-message":_vm.sendTextMessage},scopedSlots:_vm._u([{key:"room-header",fn:function(){return [_c('v-container',[_c('v-row',{staticClass:"align-center"},[(_vm.chatLogoEnabled && _vm.chatLogo)?_c('v-col',{staticStyle:{"display":"flex"},attrs:{"cols":"6"}},[_c('img',{staticClass:"d-inline-block",attrs:{"src":_vm.chatLogo,"height":"40","contain":"","position":"left","alt":"logo"}})]):_vm._e(),(_vm.chatLogoEnabled)?_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" powered by "),_c('b',[_vm._v("masspredict")])]):_vm._e(),(!_vm.chatLogoEnabled)?_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Masspredict ")]):_vm._e()],1),_c('v-row',{staticStyle:{"margin-top":"0"}},[_c('v-col',{staticClass:"text-right",staticStyle:{"padding":"0px"}},[_c('span',{staticClass:"progressbar-value",staticStyle:{"padding-right":"10px"},domProps:{"textContent":_vm._s(_vm.progress + ' %')}}),_c('v-progress-linear',{attrs:{"color":"white","height":"3"},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}})],1)],1)],1)]},proxy:true},{key:"message",fn:function(ref){
                       var message = ref.message;
return [(message.isIframe)?_c('div',{staticClass:"vac-message-card"},[_c('span',[_vm._v(_vm._s(message.caption))]),_c('div',{domProps:{"innerHTML":_vm._s(message.content)}}),_c('div',{staticClass:"vac-text-timestamp"},[_c('span',[_vm._v(_vm._s(message.timestamp))])])]):_vm._e()]}}],null,false,9584262)}),_c('div',{staticStyle:{"display":"none"}},[_c('slider-input',{attrs:{"slider-value":_vm.currentSliderValue},on:{"submitPointEstimate":_vm.sendSliderMessage}}),_c('buttons',{attrs:{"buttons":_vm.currentButtons},on:{"submitButton":_vm.sendButtonMessage}}),_c('typing-indicator',{attrs:{"lang":_vm.currentLanguage}}),_c('div',{attrs:{"id":"empty-input"}})],1)],1)],1):_vm._e()])],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),(_vm.showSimulator)?_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-btn',{staticClass:"mr-md-6 mr-2",attrs:{"outlined":""},on:{"click":_vm.simulateParticipant}},[_c('span',{staticStyle:{"padding-top":"3px"}},[_vm._v(_vm._s(_vm.simulateButtonText))]),(!_vm.simulatorRunning)?_c('v-icon',{staticClass:"pl-2"},[_vm._v(" "+_vm._s("mdi-robot"))]):_vm._e(),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.simulatorRunning),expression:"simulatorRunning"}],attrs:{"size":24,"indeterminate":"","color":"primary"}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }