<template>
  <v-container>
    <v-row>
      <v-col>
        <div v-if="loading" class="loading">
          <v-dialog v-model="loading" outlined persistent width="300">
            <v-card outlined :light="theme != 'dark'">
              <v-card-text class="mt-2">
                Please stand by
                <v-progress-linear indeterminate :light="theme != 'dark'" class="mb-2 mt-2"></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <div v-else>
          <div v-if="ended" class="loading">
            <v-dialog v-model="ended" persistent width="300">
              <v-card color="black" dark>
                <v-card-text>
                  Thank you for participating!
                  <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>
        <div v-if="message" class="session-state">
          <img id="mp-logo" :src="logoUrl" alt="Masspredict logo" />
          <div class="mb-6">
            <h2 class="mp-info" :style="`color: ${theme === 'dark' ? 'white' : 'black'};`">
              {{ message }}
            </h2>
          </div>
          <v-btn color="secondary" large outlined to="/signup">
            Create your own study
          </v-btn>
        </div>
        <div v-if="show">
          <v-card :height="calcHeight">
            <chat-window height="100%" :current-user-id="currentUserId" :rooms="rooms" :rooms-loaded="false"
                         :messages="messages" :messages-loaded="true" :show-files="false" :show-emojis="false" :show-audio="false"
                         :show-reaction-emojis="false" :show-new-messages-divider="false" :text-formatting="{ disabled: true }"
                         :message-actions="[]" :room-info-enabled="false" :single-room="true"
                         :text-messages="{ TYPE_MESSAGE: placeholder }" :auto-scroll="{
                           send: {
                             new: true,
                             newAfterScrollUp: true,
                           },
                           receive: {
                             new: true,
                             newAfterScrollUp: true,
                           },
                         }" theme="dark" :style="cssProps" @send-message="sendTextMessage"
            >
              <template #room-header>
                <v-container>
                  <v-row class="align-center">
                    <v-col v-if="chatLogoEnabled && chatLogo" cols="6" style="display: flex">
                      <img :src="chatLogo" height="40" class="d-inline-block" contain position="left" alt="logo" />
                    </v-col>
                    <v-col v-if="chatLogoEnabled" cols="6">
                      powered by <b>masspredict</b>
                    </v-col>
                    <v-col v-if="!chatLogoEnabled" cols="6">
                      Masspredict
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: 0">
                    <v-col style="padding: 0px" class="text-right">
                      <span class="progressbar-value" style="padding-right: 10px" v-text="progress + ' %'"></span>
                      <v-progress-linear v-model="progress" color="white" height="3">
                      </v-progress-linear>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template #message="{ message }">
                <div v-if="message.isIframe" class="vac-message-card">
                  <span>{{ message.caption }}</span>
                  <div v-html="message.content"></div>
                  <div class="vac-text-timestamp">
                    <span>{{ message.timestamp }}</span>
                  </div>
                </div>
              </template>
            </chat-window>
            <div style="display: none">
              <slider-input :slider-value="currentSliderValue" @submitPointEstimate="sendSliderMessage" />
              <buttons :buttons="currentButtons" @submitButton="sendButtonMessage" />
              <typing-indicator :lang="currentLanguage" />
              <div id="empty-input"></div>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row v-if="showSimulator">
      <v-col style="display: flex;justify-content: center;">
        <v-btn outlined class="mr-md-6 mr-2"  @click="simulateParticipant">
          <span style="padding-top: 3px;">{{ simulateButtonText }}</span>
          <v-icon v-if="!simulatorRunning" class="pl-2"> {{ "mdi-robot" }}</v-icon>
          <v-progress-circular v-show="simulatorRunning" :size="24" indeterminate
                               color="primary""
          ></v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import SliderInput from "../components/SliderInput.vue";
import Buttons from "../components/Buttons.vue";
import TypingIndicator from "../components/TypingIndicator.vue";
import api from "../api";
import VueCookie from "vue-cookie";
import Vue from "vue";
import axios from "axios";
import { readToken } from '@/store/main/getters';

Vue.use(VueCookie);

const themes = {
  "green": {
    "chat-theme": "green",
    "chat-content-bg-color": "#F5F5F6",
    "chat-header-bg-color": "#004d2d",
    "chat-message-bg-color": "#197a56",
    "chat-message-bg-color-me": "#50aa83",
    "chat-color-button": "#F5F5F6",
    "chat-footer-bg-color": "#004d2d",
    "chat-bg-color-input": "#50aa83",
    "chat-color-placeholder": "#004d2d",
    "chat-message-color": "#ffffff",
    "chat-color": "#ffffff"
  },
  "light": {
    "chat-theme": "light",
    "chat-content-bg-color": "#F5F5F6",
    "chat-header-bg-color": "#494949",
    "chat-message-bg-color": "#757575",
    "chat-message-bg-color-me": "#a4a4a4",
    "chat-color-button": "#F5F5F6",
    "chat-footer-bg-color": "#494949",
    "chat-bg-color-input": "#a4a4a4",
    "chat-color-placeholder": "#494949",
    "chat-message-color": "#ffffff",
    "chat-color": "#ffffff"
  },
  "blue": {
    "chat-theme": "blue",
    "chat-content-bg-color": "#F5F5F6",
    "chat-header-bg-color": "#0069c0",
    "chat-message-bg-color": "#2196f3",
    "chat-message-bg-color-me": "#6EC6FF",
    "chat-color-button": "#F5F5F6",
    "chat-footer-bg-color": "#0069c0",
    "chat-bg-color-input": "#6EC6FF",
    "chat-color-placeholder": "#0069c0",
    "chat-message-color": "#ffffff",
    "chat-color": "#ffffff"
  },
  "dark": {
    "chat-theme": "dark",
    "chat-content-bg-color": "#131415",
    "chat-header-bg-color": "#181A1B",
    "chat-message-bg-color": "#222429",
    "chat-message-bg-color-me": "#346769",
    "chat-color-button": "#ffffff",
    "chat-footer-bg-color": "#181A1B",
    "chat-bg-color-input": "#202223",
    "chat-color-placeholder": "#596269",
    "chat-message-color": "#ffffff",
    "chat-color": "#ffffff"
  }
}

export default {
  name: "Chat",
  components: {
    ChatWindow,
    SliderInput,
    Buttons,
    TypingIndicator,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    selectedTheme: {
      type: String,
      default: "dark",
    },
    showSimulator: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    selectedTheme: {
      handler: function (val) {
        this.theme = val;
        this.has_custom_chat_style = true;
      },
      immediate: true,
    },
  },
  data() {
    return {
      simulateButtonText: "Answer for me",
      simulatorRunning: false,
      logoUrl:
        "https://prod-masspredict-chat-widget.s3.eu-north-1.amazonaws.com/Logo_white.png",
      show: false,
      ended: false,
      loading: true,
      participant_id: null,
      session_id: this.$props.id ? this.$props.id : this.$route.params.id,
      is_component: this.$props.id ? true : false,
      firstLoad: true,
      session_state: "",
      currentLanguage: "EN-GB",
      botHeader: { "content-type": "application/json" },
      currentButtons: [],
      placeholder: "",
      sessionStates: {
        deleted: "deleted",
        closed: "complete",
        draft: "draft",
        active: "active",
      },
      displayMessages: {
        message: "",
        ended: "This study has ended.",
        deleted: "This study no longer exists.",
        participated: "You have already participated in this study.",
        error:
          "There seems to be an issue with this study, please try again later.",
      },
      currentInputBoxKey: "text",
      currentSliderValue: 50,
      currentUserId: 1337,
      message: "",
      rooms: [
        {
          roomId: 1,
          roomName: "Masspredict",
          users: [{ _id: "masspredict" }, { _id: this.currentUserId }],
        },
      ],
      messages: [],
      currentInputType: "start",
      has_custom_chat_style: false,
      chat_style: null,
      chatLogo: null,
      chatLogoEnabled: false,
      theme: this.$props.selectedTheme,
      bg_video: require('@/assets/videos/globe_loop.mp4'),
      embed_code: '',
      video: '',
      progress: 0,
      num_of_questions: 0,
    };
  },
  computed: {
    cssProps() {
      if (this.has_custom_chat_style) {
        return {
          "--chat-content-bg-color": themes[this.theme]["chat-content-bg-color"],
          "--chat-header-bg-color": themes[this.theme]["chat-header-bg-color"],
          '--chat-message-bg-color': themes[this.theme]["chat-message-bg-color"],
          '--chat-message-bg-color-me': themes[this.theme]["chat-message-bg-color-me"],
          "--chat-color-button": themes[this.theme]["chat-color-button"],
          "--chat-footer-bg-color": themes[this.theme]["chat-footer-bg-color"],
          "--chat-bg-color-input": themes[this.theme]["chat-bg-color-input"],
          "--chat-color-placeholder": themes[this.theme]["chat-color-placeholder"],
          "--chat-message-color": themes[this.theme]["chat-message-color"],
          "--chat-color": themes[this.theme]["chat-color"],
        };
      } else {
        return {}
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    calcHeight() {
      return this.isMobile ? window.innerHeight + "px" : "600px";
    },
    textInputBox() {
      return document.getElementsByClassName("vac-box-footer")[0];
    },
    sliderInputBox() {
      return document.getElementById("slider-input-box");
    },
    buttonsInputBox() {
      return document.getElementById("buttons-input-box");
    },
    emptyInputBox() {
      return document.getElementById("empty-input");
    },
    typingIndicator() {
      return document.getElementById("typing-indicator");
    },
    videoOverlayBox() {
      return document.getElementById('video-overlay-box');
    },
    currentInputBox() {
      switch (this.currentInputBoxKey) {
        case "empty":
          return this.emptyInputBox;
        case "text":
          return this.textInputBox;
        case "buttons":
          return this.buttonsInputBox;
        case "slider":
          return this.sliderInputBox;
        default:
          return this.textInputBox;
      }
    },
  },
  async created() {
    axios
      .get(`${api.apiUrl}/api/v1/sessions/info/${this.session_id}`)
      .then(
        function (response) {
          this.$i18n.locale = response.data.display_lang;
          this.disqualified_should_redirect = response.data.disqualified_should_redirect;
          this.disqualified_redirect_url = response.data.disqualified_redirect_url;
          this.screening_text = response.data.screening_text;
          this.collect_email = response.data.collect_email;
          this.chat_style = response.data.chat_style;
          this.chatLogo = response.data.chat_logo;
          this.chatLogoEnabled = response.data.chat_logo_enabled;
          if (this.chat_style) {
            this.has_custom_chat_style = true;
            if (!this.is_component) {
              this.theme = this.chat_style["chat-theme"];
            }
            this.bg_video = this.theme == "dark" ? require("@/assets/videos/globe_loop.mp4") : require("@/assets/videos/globe_loop_light.mp4");
          }
        }.bind(this)
      );
    if (this.theme != "dark") {
      this.logoUrl = "https://prod-masspredict-chat-widget.s3.eu-north-1.amazonaws.com/Logo_black.png";
    }
    await this.startSession();
    switch (this.session_state) {
      case this.sessionStates.closed:
        this.message = this.displayMessages.ended;
        this.stopLoading();
        break;
      case this.sessionStates.deleted:
        this.message = this.displayMessages.deleted;
        this.stopLoading();
        break;
      default:
        this.firstLoad = false;
    }
  },
  methods: {
    async simulateParticipant() {
      const token = readToken(this.$store);
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      if (this.participant_id == null) {
        return
      }
      this.simulatorRunning = true;
      this.simulateButtonText = "Simulating...";
      const data = {};
      await axios
        .post(`${api.apiUrl}/api/v1/bot/simulate/${this.participant_id}`, data, {
          headers: headers
        })
        .then(
          async function (response) {
            const participant_response = response.data.participant_response;
            const botResponse = response.data.bot_response;
            let content = '';
            if (participant_response.type == 'start') {
              content = 'Sure, let\'s get started!';
            } else if(['contract', 'nominal_option'].includes(participant_response.type)){
              content = participant_response.message.text;
            } else {
              content = participant_response.message;
            }
            this.messages = [
              ...this.messages,
              {
                _id: this.messages.length,
                content: content.toString(),
                senderId: this.currentUserId,
                timestamp: new Date().toString().substring(16, 21),
                date: new Date().toDateString(),
              },
            ];
            // this.dispatchToBotFromAI(bot_response);
            this.currentInputType =
            botResponse[botResponse.length - 1].response_type;
            this.addBotMessage(botResponse);
            this.hideTypingIndicator();
            this.progress = botResponse[botResponse.length - 1].progress;
            this.hideInput();
            this.showTypingIndicator();
            this.simulatorRunning = false;
            this.simulateButtonText = "Answer for me";
          }.bind(this)
        );
    },
    handleSessionCompleted(redirectUrl) {
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 2000);
    },
    stopLoading() {
      this.loading = false;
    },
    async startSession() {
      if (this.session_state !== this.sessionStates.draft) {
        this.participant_id = this.$cookie.get(this.session_id);
      }

      this.is_participant_alive = false;

      if (this.participant_id !== null) {
        await axios
          .get(`${api.apiUrl}/api/v1/participants/${this.participant_id}`)
          .then(
            async function (response) {
              this.is_participant_alive = response.data.finished == null;
            }.bind(this)
          );
      }
      if (this.participant_id && !this.is_participant_alive) {
        this.message = this.displayMessages.participated;
        this.stopLoading();
      } else {
        await api
          .startSession(this.session_id, this.participant_id, window.location.search, true)
          .then(
            function (response) {
              this.num_of_questions = response.data.num_of_questions;
              this.session_state = response.data.state;
              this.currentLanguage = response.data.lang;
              if (response.data.participant_id && response.data.bot_resp) {
                this.participant_id = response.data.participant_id;
                if (this.session_state !== this.sessionStates.draft) {
                  this.$cookie.set(this.session_id, this.participant_id, {
                    expires: "7D",
                  });
                }
                this.addBotMessage(response.data.bot_resp, 0);
                this.hideTypingIndicator();
              }
            }.bind(this)
          )
          .catch(() => {
            this.message = this.displayMessages.error;
            this.stopLoading();
          });
      }
    },
    async dispatchToBotFromAI(message) {
      await api
        .dispatchToBot(this.participant_id, message, this.botHeader)
        .then((botResponse) => {
          this.currentInputType =
            botResponse.data[botResponse.data.length - 1].response_type;
          this.addBotMessage(botResponse.data);
          this.hideTypingIndicator();
          this.progress = botResponse.data[botResponse.data.length - 1].progress;
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    async dispatchToBot(message) {
      const msgToBot = JSON.stringify({
        type: this.currentInputType,
        message: message,
      });
      await api
        .dispatchToBot(this.participant_id, msgToBot, this.botHeader)
        .then((botResponse) => {
          this.currentInputType =
            botResponse.data[botResponse.data.length - 1].response_type;
          this.addBotMessage(botResponse.data);
          this.hideTypingIndicator();
          this.progress = botResponse.data[botResponse.data.length - 1].progress;
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    hideInput() {
      this.currentInputBox.replaceWith(this.emptyInputBox);
      this.currentInputBoxKey = "empty";
    },
    sendTextMessage(message) {
      this.messages = [
        ...this.messages,
        {
          _id: this.messages.length,
          content: message.content,
          senderId: this.currentUserId,
          timestamp: new Date().toString().substring(16, 21),
          date: new Date().toDateString(),
        },
      ];
      this.dispatchToBot(message.content);
      this.hideInput();
      this.showTypingIndicator();
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    sendSliderMessage(value) {
      this.messages = [
        ...this.messages,
        {
          _id: this.messages.length,
          content: this.$i18n.t("chat.estimate_slider", { value: value }),
          senderId: this.currentUserId,
          timestamp: new Date().toString().substring(16, 21),
          date: new Date().toDateString(),
        },
      ];
      this.dispatchToBot(value);
      this.hideInput();
      this.showTypingIndicator();
    },
    sendButtonMessage(button) {
      this.messages = [
        ...this.messages,
        {
          _id: this.messages.length,
          content: button.title,
          senderId: this.currentUserId,
          timestamp: new Date().toString().substring(16, 21),
          date: new Date().toDateString(),
        },
      ];
      this.dispatchToBot(button.payload);
      this.hideInput();
      this.showTypingIndicator();
    },
    showTypingIndicator() {
      const elements = document.getElementsByClassName(
        "vac-messages-container"
      )[0];
      elements.appendChild(this.typingIndicator);
    },
    async hideTypingIndicator() {
      const elements = document.getElementsByClassName(
        "vac-messages-container"
      )[0];
      if (typeof elements !== "undefined") {
        await this.sleep(1000);
        elements.removeChild(elements.lastChild);
      }
    },
    async addBotMessage(botResponse, sleep = 2000) {
      if (this.loading) {
        await this.sleep(sleep)
          .then(() => {
            this.stopLoading();
            this.show = true;
          })
          .then(async () => {
            await this.sleep(0).then(() => {
              this.hideInput();
            });
          });
      }
      await this.sleep(1000).then(() => {
        for (let i = 0; i < botResponse.length; i++) {
          const response = botResponse[i];
          if (response.text) {
            this.messages = [
              ...this.messages,
              {
                _id: this.messages.length,
                content: response.text,
                senderId: "masspredict",
                timestamp: new Date().toString().substring(16, 21),
                date: new Date().toDateString(),
              },
            ];
          }
          if (response.custom && response.custom.ended) {
            this.hideInput();
            if (response.custom.redirect_url) {
              this.handleSessionCompleted(response.custom.redirect_url);
            }
          } else if (response.custom && response.custom.user_instruction) {
            this.placeholder = response.custom.user_instruction;
            this.currentInputBox.replaceWith(this.textInputBox);
            this.currentInputBoxKey = "text";
          } else if (response.buttons) {
            this.currentButtons = response.buttons;
            this.currentInputBox.replaceWith(this.buttonsInputBox);
            this.currentInputBoxKey = "buttons";
          } else if (
            response.custom &&
            (response.custom.slider || response.custom.slider === 0)
          ) {
            this.currentSliderValue = response.custom.slider;
            this.currentInputBox.replaceWith(this.sliderInputBox);
            this.currentInputBoxKey = "slider";
          } else if (response.image && response.image.url) {
            this.messages = [
              ...this.messages,
              {
                _id: this.messages.length,
                senderId: "masspredict",
                timestamp: new Date().toString().substring(16, 21),
                date: new Date().toDateString(),
                content: response.image.caption ?? "",
                files: [
                  {
                    type: "png",
                    url: response.image.url,
                  },
                ],
              },
            ];
          } else if (response.video && response.video.embed_code) {
            let video_provider = '';
            let video_id = '';
            let youtube_embed_code = '<iframe id="videoId" width="320" height="240" src="https://www.youtube.com/embed/${video_id}?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
            let vimeo_embed_code = '<iframe id="videoId" width="320" height="240" src="https://player.vimeo.com/video/${video_id}?title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>';
            let html_code = '';
            if (response.video.embed_code.startsWith('https://www.youtube.com')) {
              video_provider = 'youtube';
              video_id = response.video.embed_code.split('v=')[1];
              html_code = youtube_embed_code.replace('${video_id}', video_id);
            }
            else if (response.video.embed_code.startsWith('https://vimeo.com')) {
              video_provider = 'vimeo';
              video_id = response.video.embed_code.substring(response.video.embed_code.lastIndexOf('/') + 1);
              html_code = vimeo_embed_code.replace('${video_id}', video_id);
            }
            this.messages = [
              ...this.messages,
              {
                _id: this.messages.length,
                senderId: "masspredict",
                timestamp: new Date().toString().substring(16, 21),
                date: new Date().toDateString(),
                content: html_code,
                caption: response.video.caption,
                isIframe: true,
              },
            ];
          } else {
            this.currentInputBox.replaceWith(this.textInputBox);
            this.currentInputBoxKey = "text";
          }
        }
      });
    },
  },
};
</script>

<style>
.v-footer {
  display: none;
}

#inverted-video {
  transform: rotateY(180deg) !important;
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}

.v-dialog--fullscreen {
  overflow-y: hidden !important;
}

.session-state {
  position: absolute;
  top: 40%;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
}

#mp-logo {
  min-width: 300px;
  width: 30%;
  margin-bottom: 10%;
}

.mp-info {
  margin-right: 3%;
  margin-left: 3%;
}

.vac-media-preview {
  position: fixed;
}

.vac-message-wrapper {
  display: flex;
}

.vac-message-wrapper>.vac-message-box {
  flex: 1;
}

.vac-message-wrapper .vac-message-box {
  max-width: 80%;
}

button {
  color: var(--chat-color-button);
}

.vue-slider-dot-handle {
  box-shadow: var(--chat-color-button) 0px 0px 2px 1px !important;
  border-color: var(--chat-color-button) !important;
}

#vac-icon-send-disabled {
  fill: #bfcad3;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: var(--chat-header-bg-color);
  border-radius: 7px;
  border: none;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--chat-header-bg-color);
}

::-webkit-scrollbar-track {
  background: var(--chat-content-bg-color);
  border: none;
}

.progressbar-value {
  font-size: 10px;
}

.progressbar-col {
  padding: 12 0 !important;
}

.vac-media-preview>.vac-svg-button {
  top: 100px !important;
  right: 50px !important;
  display: none;
}

#messages-list {
  margin-top: 80px;
}

.vac-room-header {
  height: auto !important;
}

@media only screen and (max-width: 768px) {
  .vac-room-header {
    height: auto !important;
  }
}
</style>
